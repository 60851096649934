@import '__importable.scss';
.container {
	margin-bottom: 2.4rem;
}

.accordian {
	border-radius: $radius-4;
	background-color: $gray-1;

	.content {
		padding: 0 1.2rem 1.6rem;
	}
}

.row-override {
	border-bottom: 0;
	display: flex !important;
}
