@import '__importable.scss';
.frameButton {
	width: 100%;

	span{
		justify-content: space-between !important;
	}

	@media screen and (min-width: $media-md-max) {
		width: auto;
	}
}
