@import '__importable.scss';
.searchContainer {
	margin-top: 0.8rem;
	position: relative;
	flex-grow: 1;

	> *:first-child {
		position: absolute;

		top: 50%;
		transform: translateY(-50%);
		left: 1.6rem;
	}

	.searchInput {
		height: 4rem;
		border-radius: 2rem; // can we use a variable here?
		width: 100%;
		padding-left: 4.8rem;
		background-color: $gray-1;
		border: 1px solid $gray-2;
		caret-color: $blue;

		font-family: inherit;
		font-style: normal;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 2.1rem;

		&:focus,
		&:focus-visible {
			background-color: $gray-2;
			border-color: $gray-3;
			outline: none;
		}

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}

		@media screen and (min-width: $media-sm) {
			&:hover {
				background-color: $gray-2;
			}
		}
	}
}

.buttons {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: $gray-0;
	padding: 1.6rem;
}

.noPadding {
	padding: 0 !important;
}

.option {
	@include hoverable;
	display: flex;
	align-items: center;
	padding: 1.4rem 0.8rem 1.3rem;
	gap: 0.4rem;
	border-color: $gray-2;

	img {
		width: clamp(4.8rem, 30%, 7.2rem);
		height: auto;

		// Fixes Safari "Octagon" Shape
		// Semi-relevant context around why the issue occurs here: https://stackoverflow.com/questions/17202128/rounded-cornes-border-radius-safari-issue
		clip-path: none !important;
	}

	@media screen and (min-width: $media-md) {
		gap: 0.8rem;
		padding: 0.8rem 1.6rem;
		height: 5.2rem;
		max-height: 5.2rem;
	}
}

.unavailableOption {
	@extend .option;
	pointer-events: none !important;
	cursor: not-allowed;
	opacity: 0.4;

	svg path {
		fill: $gray-4;
	}
}

.selected {
	@extend .unavailableOption;
	background-color: $gray-2;
	border-color: $blue-shade;
	opacity: unset;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.6rem;
	margin-top: 1.6rem;
}

.column {
	display: flex;
	flex-direction: column;
	span {
		font-size: 1.2rem;
	}
}

.content {
	height: calc(100% - $site-header-height);
	max-height: calc(100% - $site-header-height);
	overflow: initial !important;
	padding: 3rem 1.6rem 0;

	@media screen and (min-width: $media-sm) {
		padding: 3rem 2rem 2rem;
	}

	@media screen and (min-width: $media-md) {
		height: fit-content;
		max-height: 75vh;
		overflow: hidden !important;
	}

	.inner {
		padding: 1.6rem;
	}
}

.tabs{
	height: inherit;

	.tab-content {
		height: fit-content;
		max-height: 60vh;
		overflow-y: auto;

		@media screen and (min-width: $media-md) {
			max-height: 65vh;
			overflow: auto !important;
			padding: 0 1rem 2.6rem 0;
			scrollbar-width: thin;
		}

		&--use-padding {
			padding-bottom: 14rem;

			@media screen and (min-width: $media-sm) {
				padding: 0 1rem 12rem 0;
			}
		}
	}
}

.closeButton {
	position: absolute;
	top: 1rem;
	right: 1rem;
}

.copy {
	margin-bottom: 0.8rem;
}
