@import '__importable.scss';
.tag{
	position: absolute;
	top: -1rem;
	z-index: $zindex-3 !important;
	@media screen and (min-width: $media-lg) {
		top: 1rem;
	}
}

.price-tag{
	margin-left: 2.4rem;
	&--promo {
		margin-left: 1rem;
		cursor: pointer;
	}
}