@import '__importable.scss';
@import '@styles/colors';
@import '@styles/variables';

.swiper-slide {
	position: relative;
	width: 100%;

	/* Overrides styles for last carousel image */
	&[data-image-slide-index='2'] {
		div[data-product-type='TOP_FRAME'] {
			img {
				padding: 0;
				object-fit: cover;
				mix-blend-mode: unset;
			}
		}
	}

	&[data-image-slide-index='4'] {
		div[data-product-type^='TOP_FRAME__'] {
			img {
				height: 180%;
				max-width: 250%;
				width: 250%;
				position: absolute;
				bottom: -68%;
			}
		}
	}

	div[data-product-type='LIFESTYLE'] {
		img {
			padding: 0;
			object-fit: cover;
			mix-blend-mode: unset;
		}
	}
}

// zoom in top frame without 3/4 images
.without-3-4-image {
	&[data-image-slide-index='1'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 200%;
				max-width: 200%;
				width: 200%;
				position: absolute;
				left: -50%;
			}
		}
	}

	&[data-image-slide-index='2'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 250%;
				max-width: 250%;
				width: 250%;
				position: absolute;
				left: 5%;
				bottom: -40%;
			}
		}
	}
}

// zoom in top frame with 3/4 images
.with-3-4-image {
	&[data-image-slide-index='2'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 120%;
				max-width: 150%;
				width: 150%;
				position: absolute;
				left: -25%;
				top: 15%
			}
		}
	}

	&[data-image-slide-index='3'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 220%;
				max-width: 220%;
				width: 220%;
				position: absolute;
				left: -85%;
				top: -30%
			}
		}
	}
}

.image-container,
.main-image-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $gray-1;
	overflow: hidden;

	&:not([data-product-type='ACCESSORY']) {
		img {
			height: 100%;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			margin: auto;
			padding-inline: clamp(1.6rem, 2vw, 4.8rem);
			font-family: 'object-fit: contain;';
			object-fit: contain;
			mix-blend-mode: multiply;
			aspect-ratio: 2 / 1;

			&.primaryImage {
				mix-blend-mode: normal;
			}
		}

		&::after {
			content: '';
			display: block;
			height: 0;
			padding-top: clamp(240px, 40vw, 668px);
		}
	}

	&[data-product-type='ACCESSORY'] {
		img {
			aspect-ratio: 1.55 / 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&[data-product-title='Handy Dandy Cleaning Kit'] {
		img {
			width: 100%;
			object-fit: cover;
			padding: 0;
			object-position: 0 25%;
		}
	}
}

.image-loader {
	> div {
		top: 2rem;
	}

	@media screen and (min-width: $media-md) {
		> div {
			top: 6rem;
		}
	}
}

.image-grid-container {
	padding: unset;

	@media screen and (min-width: $media-lg) {
		padding: 2rem 0 0 2rem;
		position: sticky;
		top: 10.8rem;
	}
}

.full-width-video-slide {
	grid-column: span 2;
}

.fixed-height-container {
	height: 35rem;
}

.vtoButton {
	position: absolute;
	top: 1.6rem;
	right: 1.6rem;
	z-index: $zindex-1;
	padding: 0.6rem !important;
	border: 1px solid $blue-2;
	font-size: 1rem !important;

	@media screen and (min-width: $media-lg) {
		padding: 1rem !important;
		font-size: 1.6rem !important;
	}
}